<template>
  <customModal :visible="visible"
               @handleCancel="handleCancel"
               @handleOk="handleOk"
               showCancelBtn
               :loading="btnloading"
               title="修改来源信息">
    <div class="flex flex-direction align-center anchor-form-style"
         v-loading="loading">
      <el-form ref="starAnchorForm"
               :model="anchorDetail"
               label-width="130px"
               v-if="anchorDetail"
               :size="'mini'">
        <el-form-item label="来源："
                      prop="origin">
          <el-select v-model="anchorDetail.origin"
                     placeholder="请选择"
                     class="width100">
            <el-option v-for="item in [{
									value:0,
									label:'自招主播'
								},
								{
									value:1,
									label:'星探推荐'
								}]"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="星探："
                      v-if="anchorDetail.origin !==0">
          <div style="height:40px;width:100%;border:1px solid #DCDFE6;padding:0 8px;"
               class="flex align-center"
               @click="open">
            <el-avatar :size="36"
                       :src="anchorDetail.star_user_avatar||''" />
            <div style="margin-left:8px;font-size:12px;">{{anchorDetail.star_user_name||'选择星探'}}</div>
          </div>
        </el-form-item>
        <el-form-item label="王牌运营："
                      prop="star_broker_id"
                      v-if="anchorDetail.origin !==0">
          <superBroker @change="id=>anchorDetail.star_broker_id = id"
                       placeholder="请选择王牌运营"
                       :defaultvalue="anchorDetail.star_broker_id"
                       class="width100"></superBroker>
        </el-form-item>
      </el-form>
    </div>
  </customModal>
</template>
<script>
import superBroker from "components/formComponent/superBroker";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    anchor_id: {
      type: Number,
      default: 0
    },
    btnloading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    superBroker
  },
  data () {
    return {
      anchorDetail: null,
      starAnchor: null,  //主播来源信息
      starAnchorForm: {
        origin: 0,
        star_broker_id: ''
      }, //主播来源信息表单
      star_data: null,
      loading: false
    };
  },
  mounted () { },
  methods: {
    // 取消按钮
    handleCancel () {
      this.$emit('handleCancel')
    },
    // 确认按钮
    handleOk () {
      this.$emit("handleOk", {
        anchor_id: this.anchorDetail.anchor_id,
        origin: this.anchorDetail.origin,
        star_id: this.anchorDetail.star_user_id,
        star_broker_id: this.anchorDetail.star_broker_id
      })
    },
    //修改主播来源信息 确定
    starAnchorOk () {
      console.log("修改主播来源信息 确定")
      this._update_star_anchor()
    },
    // 输入星探手机号
    open () {
      this.$prompt('请输入星探电话号', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        this._req_star_broker(value)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    // 获取星探资料
    async _req_star_broker (phone) {
      const { status, info, data } = await this.$api.getStarBroker({
        phone,
        zn_token: this.zn_token
      });
      if (status === 200) {
        this.anchorDetail.star_user_avatar = data.avatar
        this.anchorDetail.star_user_id = data.id
        this.anchorDetail.star_user_name = data.nick_name
      } else {
        this.$message({
          message: info,
          type: "warning",
        });
      }
      this.reqLoading = false;
    },
    clear_star () {
      this.anchorDetail = null
    },
    // 获取主播星探资料
    async _getAnchorDetail (anchor_id) {
      this.loading = true
      const { status_code, message } = await this.$api.anchorDetail({ anchor_id });
      if (status_code == 200) this.anchorDetail = message;
      this.loading = false
    },
  },
  watch: {
    anchor_id: {
      handler () {
        if (this.anchor_id)
          this._getAnchorDetail(this.anchor_id)
      },
      immediate: true
    }
  },
};
</script>
<style scoped lang="scss">
@import "../style.less";
</style>