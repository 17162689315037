var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('customModal',{attrs:{"visible":_vm.visible,"showCancelBtn":"","loading":_vm.btnloading,"title":"修改来源信息"},on:{"handleCancel":_vm.handleCancel,"handleOk":_vm.handleOk}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"flex flex-direction align-center anchor-form-style"},[(_vm.anchorDetail)?_c('el-form',{ref:"starAnchorForm",attrs:{"model":_vm.anchorDetail,"label-width":"130px","size":'mini'}},[_c('el-form-item',{attrs:{"label":"来源：","prop":"origin"}},[_c('el-select',{staticClass:"width100",attrs:{"placeholder":"请选择"},model:{value:(_vm.anchorDetail.origin),callback:function ($$v) {_vm.$set(_vm.anchorDetail, "origin", $$v)},expression:"anchorDetail.origin"}},_vm._l(([{
									value:0,
									label:'自招主播'
								},
								{
									value:1,
									label:'星探推荐'
								}]),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),(_vm.anchorDetail.origin !==0)?_c('el-form-item',{attrs:{"label":"星探："}},[_c('div',{staticClass:"flex align-center",staticStyle:{"height":"40px","width":"100%","border":"1px solid #DCDFE6","padding":"0 8px"},on:{"click":_vm.open}},[_c('el-avatar',{attrs:{"size":36,"src":_vm.anchorDetail.star_user_avatar||''}}),_c('div',{staticStyle:{"margin-left":"8px","font-size":"12px"}},[_vm._v(_vm._s(_vm.anchorDetail.star_user_name||'选择星探'))])],1)]):_vm._e(),(_vm.anchorDetail.origin !==0)?_c('el-form-item',{attrs:{"label":"王牌运营：","prop":"star_broker_id"}},[_c('superBroker',{staticClass:"width100",attrs:{"placeholder":"请选择王牌运营","defaultvalue":_vm.anchorDetail.star_broker_id},on:{"change":function (id){ return _vm.anchorDetail.star_broker_id = id; }}})],1):_vm._e()],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }